import React from 'react';
import './Service.css';

class Service extends React.Component {
    render() {
        return (
            <div id="services" className="content-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-6">
                            <div className="service-item">
                                <span className="service-icon first"></span>
                                <h3>Экономия времени</h3>
                                <p>Уменьшение трудоемкости и затрат на грузоподъемные механизмы, возможен монтаж "с колес".</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="service-item">
                                <span className="service-icon second"></span>
                                <h3>Прочная конструкция</h3>
                                <p>Конструктивная особенность блоков позволяет выдерживать значительную горизонтальную нагрузку по сравнению с
                                    обычными фундаментными блоками.</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="service-item">
                                <span className="service-icon third"></span>
                                <h3>Без раствора</h3>
                                <p>При монтаже блоков нет потребности в цементно-песчаном растворе и бетоне для монолитных заделок</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="service-item">
                                <span className="service-icon fourth"></span>
                                <h3>Разборная конструкция</h3>
                                <p>Конструкции из таких блоков можно разбирать, перевозить и собирать огромное количество раз.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Service;