import React from 'react';
import './ProductPromotion.css';

class ProductPromotion extends React.Component {
    render() {
        return (
            <div id="product-promotion" className="content-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1 className="section-title">New Arrivals</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2 col-sm-3">
                            <div className="item-small">
                                <img src="images/promotion/promotion1.jpg" alt="Product 1"/>
                                    <h4>Nullam Non Ultrices Arcu</h4>
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-6">
                            <div className="item-large">
                                <img src="images/promotion/promotion2.jpg" alt="Product 2"/>
                                    <div className="item-large-content">
                                        <div className="item-header">
                                            <h2 className="pull-left">Fusce facilisis semper magna</h2>
                                            <span className="pull-right">Rate: <i className="fa fa-star"></i><i className="fa fa-star"></i><i
                                                className="fa fa-star"></i><i
                                                className="fa fa-star"></i>
                                <i className="fa fa-star-half-o"></i></span>
                                            <div className="clearfix"></div>
                                        </div>
                                        <p>Maecenas erat lacus, ultrices vel orci ac, eleifend pulvinar est. Proin mollis purus tincidunt enim aliquam
                                            posuere et eget
                                            magna. Proin sed posuere lorem. Nulla eget neque at eros ultricies venenatis eget sit amet risus. Donec
                                            ornare consequat
                                            efficitur. <br/><br/>In hac habitasse platea dictumst. Cras eget scelerisque justo, sit amet aliquam ex.
                                                Aliquam iaculis
                                                lectus accumsan, egestas lorem ac, malesuada purus.</p>
                                    </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-3">
                            <div className="item-small">
                                <img src="images/promotion/promotion3.jpg" alt="Product 3"/>
                                    <h4>Morbi Sit Amet Lorem</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductPromotion;