import React, {useRef} from "react";
import emailjs, {init} from "@emailjs/browser";
import useForm from "../UseForm/UseForm"

export function SendEmailForm(props) {
    init("user_IcuA6rZNfftdKJesOc02S");

    const form = useRef();

    const sendEmail = (evt) => {
        // evt.preventDefault();
        var $submit = document.querySelector('.submitting'),
            waitText = 'Отправка сообщения...';

        $submit.style.display = "block";
        $submit.textContent = waitText;

        emailjs
            .sendForm(
                "service_xeuvqn9",
                "template_vhd79zn",
                form.current,
                "user_IcuA6rZNfftdKJesOc02S"
            )
            .then(
                (result) => {
                    document.querySelector('#form-message-warning').style.display = 'none';
                    let success = document.querySelector('#form-message-success');
                    success.style.display = "block";

                    setTimeout(function () {
                        $submit.style.display = "none";
                        $submit.textContent = waitText;
                    }, 1400);

                    setTimeout(function () {
                        success.style.display = "none";
                    }, 1400);

                    setTimeout(function () {
                        form.current.reset();
                    }, 1400);
                },
                (error) => {
                    let warning = document.querySelector('#form-message-warning');
                    warning.textContent = "Сообщение не отправлено. Пожалуйста, попробуйте позже.";
                    warning.style.display = "block";
                    $submit.style.display = "none";
                    setTimeout(function () {
                        warning.style.display = "none";
                    }, 3000);
                }
            );
    }

    const {handleChange, errors, handleSubmit} = useForm(sendEmail);

    return (
        <form id="contactForm" name="contactForm" className="contactForm" onSubmit={handleSubmit} ref={form}>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="label" htmlFor="name">Ваше имя</label>
                        <input type="text" className="form-control" name="name" id="name" onChange={handleChange}
                               placeholder="Иван"/>
                        {
                            errors.name && <div className="contact-invalid-feedback">{errors.name}</div>
                        }
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="label" htmlFor="email">Ваш Email</label>
                        <input type="email" className="form-control" name="email" id="email" onChange={handleChange}
                               placeholder="ivan@gmail.com"/>
                        {
                            errors.email && <div className="contact-invalid-feedback">{errors.email}</div>
                        }
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="label" htmlFor="email">Ваш телефон</label>
                        <input type="text" className="form-control" name="phone" id="phone" onChange={handleChange}
                               placeholder="+375111111111"/>
                        {
                            errors.phone && <div className="contact-invalid-feedback">{errors.phone}</div>
                        }
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="label" htmlFor="email">Ваш город</label>
                        <input type="text" className="form-control" name="city" id="city" onChange={handleChange}
                               placeholder="Минск"/>
                        {
                            errors.city && <div className="contact-invalid-feedback">{errors.city}</div>
                        }
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="label" htmlFor="subject">Тема</label>
                        <input type="text" className="form-control" name="subject" id="subject"
                               onChange={handleChange}
                               placeholder="Вопрос"/>
                        {
                            errors.subject && <div className="contact-invalid-feedback">{errors.subject}</div>
                        }
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="label" htmlFor="#">Сообщение</label>
                        <textarea name="message" className="form-control" id="message" cols="30" rows="4"
                                  onChange={handleChange}
                                  placeholder="Хочу поинтересоваться..."></textarea>
                        {
                            errors.message && <div className="contact-invalid-feedback">{errors.message}</div>
                        }
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <input type="submit" value="Отправить" className="btn btn-primary"/>
                        <div className="submitting"></div>
                    </div>
                </div>
            </div>
        </form>
    );
}