import React from 'react';
import './Contact.css';
import {SendEmailForm} from "../SendEmailForm/SendEmailForm";


class Contact extends React.Component {

    render() {
        return (
            <section id="contact" className="ftco-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1 className="section-title">Контакты</h1>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="wrapper">
                                <div className="row no-gutters">
                                    <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                                        <div className="contact-wrap w-100 p-md-5 p-4">
                                            <h3 className="mb-4">Отправить сообщение</h3>
                                            <div id="form-message-warning" className="mb-4"></div>
                                            <div id="form-message-success" className="mb-4">
                                                Ваше сообщение было отправлено.
                                            </div>
                                            <SendEmailForm/>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-5 d-flex align-items-stretch">
                                        <div className="info-wrap bg-primary w-100 p-md-5 p-4">
                                            <h3>Информация</h3>
                                            <p className="mb-4">ООО «ЛегоБлок»<br/>УНП 193621423</p>
                                            <div className="dbox w-100 d-flex align-items-start">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-map-marker"></span>
                                                </div>
                                                <div className="text pl-3">
                                                    <p><span>Юридический адрес:</span> Республика Беларусь 220115
                                                        <br/>
                                                        г. Минск,
                                                        <br/>
                                                        пер. Корженевского, д. 2А, пом.3Н
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dbox w-100 d-flex align-items-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-phone"></span>
                                                </div>
                                                <div className="text pl-3">
                                                    <p><span>Телефон:</span> <br/><a href="tel://+375293946980">+ 375-29-394-69-80</a></p>
                                                </div>
                                            </div>
                                            <div className="dbox w-100 d-flex align-items-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-envelope-o"></span>
                                                </div>
                                                <div className="text pl-3">
                                                    <p><span>Email:<br/></span> <a href="mailto:legoblock.by@gmail.com">legoblock.by@gmail.com</a></p>
                                                </div>
                                            </div>
                                            <div className="dbox w-100 d-flex align-items-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-clock-o"></span>
                                                </div>
                                                <div className="text pl-3">
                                                    <p><span>Время работы:</span>
                                                        <br/>
                                                        Пн.-Сб.: 8.00 - 20.00</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


export default Contact;