import {useState} from 'react'
import {omit} from 'lodash'

const useForm = (callback) => {

    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});

    const validate = (event, name, value) => {
        //A function to validate each input values

        switch (name) {
            case 'name':
                if (value.length < 3) {
                    // we will set the error state

                    setErrors({
                        ...errors,
                        name: 'Имя должно быть больше 3 букв'
                    })
                } else {
                    // set the error state empty or remove the error for username input

                    //omit function removes/omits the value from given object and returns a new object
                    let newObj = omit(errors, "name");
                    setErrors(newObj);
                }
                break;

            case 'city':
                if (value.length < 3) {
                    setErrors({
                        ...errors,
                        city: 'Город должен быть больше 3 букв'
                    })
                } else {
                    let newObj = omit(errors, "city");
                    setErrors(newObj);
                }
                break;

            case 'phone':
                if (checkPhone(value)) {
                    let newObj = omit(errors, "phone");
                    setErrors(newObj);
                } else {
                    setErrors({
                        ...errors,
                        phone: 'Телефон должен соответствовать шаблону +375-xx-xxx-xx-xx'
                    })
                }
                break;

            case 'email':
                if (
                    !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                ) {
                    setErrors({
                        ...errors,
                        email: 'Введите правильный email адрес'
                    })
                } else {
                    let newObj = omit(errors, "email");
                    setErrors(newObj);
                }
                break;

            case 'subject':
                if (value.length < 1) {
                    setErrors({
                        ...errors,
                        subject: 'Укажите тему сообщения'
                    })
                } else {
                    let newObj = omit(errors, "subject");
                    setErrors(newObj);

                }
                break;
            case 'message':
                if (value.length < 1) {
                    setErrors({
                        ...errors,
                        message: 'Введите ваше сообщение'
                    })
                } else {
                    let newObj = omit(errors, "message");
                    setErrors(newObj);
                }
                break;

            default:
                break;
        }
    }

    const checkPhone = (phone) => {
        var pattern = /^\+?(\d{3})\)?[- ]?[(]?(\d{2})?[)][- ]?(\d{3})[- ]?(\d{2})[- ]?(\d{2})$/;
        return !!(phone.match(pattern));
    };

    //A method to handle form inputs
    const handleChange = (event) => {
        //To stop default events
        event.persist();

        let name = event.target.name;
        let val = event.target.value;

        validate(event, name, val);
        //Let's set these values in state

        setValues({
            ...values,
            [name]: val,
        })

    }


    const handleSubmit = (event) => {
        if (event) event.preventDefault();

        if (Object.keys(errors).length === 0 && Object.keys(values).length !== 0) {
            callback();

        } else {
            let warning = document.querySelector('#form-message-warning');
            warning.textContent = "Есть ошибки!";
            warning.style.display = "block";
            setTimeout(function () {
                warning.style.display = "none";
            }, 3000);
        }
    }

    return {
        values,
        errors,
        handleChange,
        handleSubmit
    }
}

export default useForm;