import React from 'react';
import './Cookie.css';
import {Container, Nav, Navbar} from "react-bootstrap";

class Cookie extends React.Component {
    render() {
        return (
            <div class="cookie_notice">
                Этот сайт использует файлы cookies и сервисы сбора технических данных посетителей (данные об IP-адресе, местоположении и др.) для обеспечения работоспособности и улучшения качества обслуживания. Нажимая кнопку «Согласиться» вы даёте разрешение на обработку персональных данных.
                <div>
                    <a class="cookie_btn" id="cookie_close">Согласиться</a>
                </div>
            </div>
        );
    }
}

// функция возвращает cookie с именем name, если есть, если нет, то undefined
function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

window.addEventListener("load", function() {
    let cookiecook = getCookie("cookiecook-legoblock"),
        cookiewin = document.getElementsByClassName('cookie_notice')[0];
// проверяем, есть ли у нас cookie, с которой мы не показываем окно и если нет, запускаем показ
    if (cookiecook != "no") {
        // показываем
        cookiewin.style.display = "block";
        // закрываем по клику
        document.getElementById("cookie_close").addEventListener("click", function () {
            cookiewin.style.display = "none";
            // записываем cookie на 1 день, с которой мы не показываем окно
            let date = new Date();
            date.setDate(date.getDate() + 1);
            document.cookie = "cookiecook-legoblock=no; path=/; expires=" + date.toUTCString();
        });
    }
});

export default Cookie;