import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import NaviBar from './components/NaviBar/NaviBar'
import SiteSlider from "./components/SiteSlider/SiteSlider";
import Service from "./components/Service/Service";
import ProductPromotion from "./components/ProductPromotion/ProductPromotion";
import Product from "./components/Product/Product";
import Contact from "./components/Contact/Contact";
import SiteFooter from "./components/SiteFooter/SiteFooter";
import Cookie from "./components/Cookie/Cookie";


const root = ReactDOM.createRoot(document.getElementById('root'));
// const priceFromJson = fetch('price.json');
// const priceFromJson = [
//     {
//         "fileName": "16.4.4.jpg",
//         "name": "16.4.4",
//         "price": "101.28"
//     },
//     {
//         "fileName" : "16.4.4-00.jpg",
//         "name": "16.4.4-00",
//         "price" : "108.04"
//     },
//     {
//         "fileName" : "16.4.4-45.jpg",
//         "name": "16.4.4-45",
//         "price" : "100.44"
//     }
// ];
const data = [
    {
        image:
            "./images/slider/slide-01.webp",
        caption: `<div>Slide 1</div>`,
    },
    {
        image:
            "./images/slider/slide-02.webp",
        caption: `<div>Slide 2</div>`,
    },
    {
        image:
            "./images/slider/slide-03.webp",
        caption: `<div>Slide 3</div>`,
    },
    {
        image:
            "./images/slider/slide-04.webp",
        caption: `<div>Slide 4</div>`,
    },
    {
        image:
            "./images/slider/slide-05.webp",
        caption: `<div>Slide 5</div>`,
    },
    {
        image:
            "./images/slider/slide-06.webp",
        caption: `<div>Slide 6</div>`,
    },
    {
        image:
            "./images/slider/slide-07.webp",
        caption: `<div>Slide 7</div>`,
    },
    {
        image:
            "./images/slider/slide-08.webp",
        caption: `<div>Slide 8</div>`,
    },
    {
        image:
            "./images/slider/slide-09.webp",
        caption: "<div>Slide 9</div>",
    },
];

const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
};
const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
};
root.render(
    <React.StrictMode>

        <NaviBar/>
        <SiteSlider
            data={data}
            time={3000}
            width={1024}
            height={512}
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={false}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            withText={false}
            automatic={true}
            dots={false}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="none"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            thumbnailHeight="50px"
            showNavBtn={true}
            style={{
                textAlign: "center",
            }}
        />
        <Service/>
        {/*<ProductPromotion/>*/}
        <Product/>
        <Contact/>
        <SiteFooter/>
        <Cookie/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
