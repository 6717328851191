import React, {useState} from 'react';
import './Product.css';

function Product (){
    // react Hook For State Handler
    const [price , setPrice]=useState(null)

// Fetch Function
    fetch("./price.json").then(
        function(res){
            return res.json()
        }).then(function(data){
        // store Data in State Data Variable
        setPrice(data)
    }).catch(
        function(err){
            console.log(err, ' error')
        }
    )
        return (
            <div id="products" className="content-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1 className="section-title">Продукция</h1>
                        </div>
                    </div>

                    <div className="row">
                        {
                            price? price.map(
                                function(item, index){
                                    return (
                                        <div className="col-md-4 col-sm-6"  key={"product_" + index}>
                                            <div className="product-item text-center">
                                                <div className="item-thumb">
                                                    <img src={"images/products/" + item.fileName} alt=""/>
                                                </div>
                                                <h3>{item.name}</h3>
                                                <span>Цена: <em className="price">{item.price} BYN</em></span>
                                            </div>
                                        </div>
                                    );
                                }
                            ):""
                        }
                    </div>
                    <div className="row">
                        <p className="product-text">Все блоки выполнены из бетона C16/20(M250). Размеры блоков указаны в дециметрах в следующей последовательности – длина, толщина (ширина),
                            высота. Дополнительные элементы обозначения указывают на специфичность блока, такие как, с плоским верхом (00), радиусные
                            (R), с углом в 45&#176;  (45). Длина типового блока может быть выполнена кратной 40 см, на примере блока 16.4.4 возможные
                            варианты исполнения - 12.4.4, 8.4.4, 4.4.4.</p>
                    </div>
                </div>
            </div>
        );
}

export default Product;