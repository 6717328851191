import React from 'react';
import './SiteFooter.css';

class SiteFooter extends React.Component {
    render() {
        return (
            <div className="site-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-6">
                    <span id="copyright">
                   	Copyright &copy; 2022. Design: DT </span>

                        </div>
                        <div className="col-md-4 col-sm-6">
                            <ul className="social">
                                {/*<li><a href="https://www.instagram.com/legoblock.by/" className="fa fa-instagram" rel="nofollow" target="_blank"></a></li>*/}
                                <li><a href="https://www.instagram.com/legoblock.by/"  rel="nofollow" target="_blank"><img src="/images/instagram-logo.svg" width="16" height="16" alt=""/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SiteFooter;